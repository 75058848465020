import {parseNumber, parseRentUnit} from "~/utils/parseTool.js";
import holidays from "~/config/holidays.js";

/// {'0':價錢, '1':價錢, '4':價錢} (沒有就是沒有該類型)
export function getTypePrice(rentGroups) {
    const overallCheapestPrices = getCheapestPrices(rentGroups)

    const cheapestPrice = {}
    if (overallCheapestPrices.cheapestHourlyPrice) {
        cheapestPrice['0'] = overallCheapestPrices.cheapestHourlyPrice
    }
    if (overallCheapestPrices.cheapestDailyPrice) {
        cheapestPrice['1'] = overallCheapestPrices.cheapestDailyPrice
    }
    if (overallCheapestPrices.cheapestPeriodPrice) {
        cheapestPrice['4'] = overallCheapestPrices.cheapestPeriodPrice
    }

    return cheapestPrice
}

/// 所有專案 最小價錢 (null 就是沒有這個類型)
/// {
//     "cheapestHourlyPrice": 100,
//     "cheapestDailyPrice": 200,
//     "cheapestPeriodPrice": null
// }
export function getCheapestPrices(rentGroups) {
    // 遍歷所有的 rentGroup, 組出每組專案每個類型的最小價格
    const prices = getAllProjectCheapestPrices(rentGroups)

    // 篩選出所有專案、類型中的最小價格
    const overallCheapestPrices = prices.reduce((acc, current) => {
        return {
            cheapestHourlyPrice: Math.min(acc.cheapestHourlyPrice, current.cheapestHourlyPrice || Infinity),
            cheapestDailyPrice: Math.min(acc.cheapestDailyPrice, current.cheapestDailyPrice || Infinity),
            cheapestPeriodPrice: Math.min(acc.cheapestPeriodPrice, current.cheapestPeriodPrice || Infinity)
        }
    }, {
        cheapestHourlyPrice: Infinity,
        cheapestDailyPrice: Infinity,
        cheapestPeriodPrice: Infinity
    })

    // 如果有值是 Infinity，則設為 null
    overallCheapestPrices.cheapestHourlyPrice = overallCheapestPrices.cheapestHourlyPrice === Infinity ? null : overallCheapestPrices.cheapestHourlyPrice
    overallCheapestPrices.cheapestDailyPrice = overallCheapestPrices.cheapestDailyPrice === Infinity ? null : overallCheapestPrices.cheapestDailyPrice
    overallCheapestPrices.cheapestPeriodPrice = overallCheapestPrices.cheapestPeriodPrice === Infinity ? null : overallCheapestPrices.cheapestPeriodPrice

    return overallCheapestPrices
}

/// 各專案 最小價錢 (null 就是沒有這個類型)
/// [{
//     "cheapestHourlyPrice": 100,
//     "cheapestDailyPrice": 200,
//     "cheapestPeriodPrice": null
// }, .....]
export function getAllProjectCheapestPrices(rentGroups) {
    return rentGroups.map(group => getProjectCheapestPrices(group))
}

/// 單專案 最小價格
/// {
//     "cheapestHourlyPrice": 100,
//     "cheapestDailyPrice": 200,
//     "cheapestPeriodPrice": null
// }
export function getProjectCheapestPrices(group) {
    const rentTypes = group.rent_types

    // 初始化最便宜價格
    let cheapestHourlyPrice = Infinity
    let cheapestDailyPrice = Infinity
    let cheapestPeriodPrice = Infinity

    // 遍歷每個租金類型
    for (const [type, details] of Object.entries(rentTypes)) {
        let allPrices = []

        // 獲取每個類型內的所有價格
        if (details['week_days']) {
            Object.values(details['week_days']).forEach(dayPrices => {
                dayPrices.forEach(item => {
                    allPrices.push(parseFloat(item.price))
                })
            })
        }

        if (details['holiday']) {
            details['holiday'].forEach(item => {
                allPrices.push(parseFloat(item.price))
            })
        }

        if (details['dates']) {
            Object.values(details['dates']).forEach(datePrices => {
                datePrices.forEach(item => {
                    allPrices.push(parseFloat(item.price))
                })
            })
        }

        // 找到該類型中的最小價格
        const minPrice = allPrices.length > 0 ? Math.min(...allPrices) : Infinity

        // 判斷類型並更新對應的最小價格
        if (type === "0") {
            cheapestHourlyPrice = Math.min(cheapestHourlyPrice, minPrice)
        } else if (type === "1") {
            cheapestDailyPrice = Math.min(cheapestDailyPrice, minPrice)
        } else if (type === "4") {
            cheapestPeriodPrice = Math.min(cheapestPeriodPrice, minPrice)
        }
    }

    return {
        cheapestHourlyPrice: cheapestHourlyPrice === Infinity ? null : cheapestHourlyPrice,
        cheapestDailyPrice: cheapestDailyPrice === Infinity ? null : cheapestDailyPrice,
        cheapestPeriodPrice: cheapestPeriodPrice === Infinity ? null : cheapestPeriodPrice
    }
}

/// 拉出時租最小租期
export function getMinShortestRentalDuration(rentGroups) {
    const shortestRentalDurations = rentGroups.map(group => {
        const rentType = group.rent_types["0"]
        if (!rentType) return 0

        // 收集所有 shortest_rental_duration 值
        let durations = []

        // 遍歷 week_days
        for (const day in rentType.week_days) {
            rentType.week_days[day].forEach(entry => {
                durations.push(entry.shortest_rental_duration);
            })
        }

        // 遍歷 holiday
        rentType.holiday.forEach(entry => {
            durations.push(entry.shortest_rental_duration)
        })

        // 遍歷 dates
        for (const date in rentType.dates) {
            rentType.dates[date].forEach(entry => {
                durations.push(entry.shortest_rental_duration)
            })
        }

        // 找出最小值
        return durations.length > 0 ? Math.min(...durations) : 0
    })
    return Math.min(...shortestRentalDurations)
}

/// 最便宜價錢 for list
export function parseFormatCheapestRentPrice(rentGroups) {
    const price = getCheapestRentObject(rentGroups)[1]
    return 'NT$' + parseNumber(price)
}

/// 最便宜的類型 for list
export function parseFormatCheapestRentType(rentGroups) {
    const type = getCheapestRentObject(rentGroups)[0]
    return parseRentUnit(type)
}

/// 取得所有方案便宜 [類型, 價格] for list
// ['0', 價錢] or ['1', 價錢] or ['4', 價錢]
export function getCheapestRentObject(rentGroups) {
    return Object.entries(getTypePrice(rentGroups)).reduce((minEntry, currentEntry) => {
        return currentEntry[1] < minEntry[1] ? currentEntry : minEntry
    })
}

/// 選日期後，拉取價格設定參數
/// date : yyyy/MM/dd
//[
//     {
//         "start": "12:00",
//         "end": "23:59",
//         "price": "20",
//         "shortest_rental_duration": 1.0
//     }, ...
// ]
export function getRentSettingByDate(rent_type, date) {
    /// 1.
    // "dates":{
    //     "2024-12-31":[
    //         {
    //             "start": "12:00",
    //             "end": "23:59",
    //             "price": "20",
    //             "shortest_rental_duration": 1.0
    //         }
    //     ]
    // }
    /// 2.
    // "holiday":[{ ... }],
    /// 3.
    // "week_days":{
    //     "1": [{ ... }], "2": [{ ... }], ...
    // }

    /// 先判斷特殊日
    if (rent_type.dates) {
        // 將 date 格式化成 "YYYY-MM-DD"
        const formattedDate = date.replace(/\//g, "-")
        if (Object.keys(rent_type.dates).includes(formattedDate)) {
            return rent_type.dates[formattedDate]
        }
    }

    /// 判斷假日
    if (rent_type.holiday && rent_type.holiday.length > 0) {
        // 將 date 格式化成 "YYYYMMDD"
        const formattedDate = date.replace(/\//g, "")
        if (holidays.some(holiday => holiday.date === formattedDate)) {
            return rent_type.holiday
        }
    }

    /// 判斷平日
    if (rent_type.week_days) {
        return rent_type.week_days[getWeekDay(new Date(date))]
    }
}

/// 星期日 0, 星期一 1...
export function getWeekDay(date) {
    const day = date.getDay();
    return day === 7 ? 0 : day;
}

/// 拉不到，日期就擋住不給選
export function isDateLocked(rent_type, date) {
    return getRentSettingByDate(rent_type, date) === undefined
}
